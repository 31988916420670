<template>
    <div id="test6">
        <div class="crumbs">车辆列表 / 维修保养详情</div>
        <div class="center">
            <div class="table">
                <el-table :data="repair_info" style="width: 100%"
                    :header-cell-style="{ background: '#F5F7FA', 'text-align': 'center' }">
                    <el-table-column prop="repair_log" label="保养记录" :align="'center'">
                    </el-table-column>
                    <el-table-column prop="repair_time" label="保养金额" :align="'center'">
                    </el-table-column>
                </el-table>
            </div>
            <div class="table">
                <el-table :data="maintain_info" style="width: 100%"
                    :header-cell-style="{ background: '#F5F7FA', 'text-align': 'center' }">
                    <el-table-column prop="maintain_log" label="维修记录" :align="'center'">
                    </el-table-column>
                    <el-table-column prop="maintain_fee" label="维修金额" :align="'center'">
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import { archivesInfo } from "../request/api"
export default {
    name: 'DemoTest6',
    data() {
        return {
            maintain_info: [],
            repair_info: []
        };
    },
    async mounted() {
        let res = await archivesInfo(
            {
                vihicle_id: Number(this.$route.query.id),
                type: 2
            }
        )
        this.maintain_info = res.data.info.maintain_info
        this.repair_info = res.data.info.repair_info
    },
    methods: {


    },
};
</script>

<style lang="less" scoped>
#test6 {
    width: 100%;
    height: 100vh;

}

.crumbs {
    padding: 5px 20px;
    background-color: rgb(240, 240, 240);
}

.center {
    width: 100vw;
    height: calc(100% - 31px);
    // background-color: #000;
    margin: auto;
    display: flex;



    .center_top {
        padding-top: 10px;
        padding-bottom: 13px;
        height: 36px;
        display: flex;
        justify-content: center;

        ::v-deep .el-input__inner {
            width: 190px;
            height: 32px;
        }
    }

    .table {
        height: 100%;
        overflow: auto;
        padding: 0 10px;
        flex: 1;
    }
}
</style>